export const lightTheme = {
    mode: 'light',
    primary: '#386a20',
    secondary: 'rgb(19 31 13)',
    text: 'rgb(31 31 31)',
    textAlt: 'rgb(69 71 70)',
    textOpp: '#e3e3e3',
    textGrey: '#afb8c4',
    backgroundColor: '#f8fafd',
    backgroundColor: '#ffffff',
    backgroundColor: 'rgb(253 253 246)',
    card: '#ebefe2',
    cardLight: '#e2e8f0',
    cardLight: 'rgb(241 243 232)',
    cardLighter: '#f4f6fc',
    cardLighter: '#ffffff',
    borderColor: '#e2e8f0',
    borderColor2: '#e2e8f0',
    hoverShadowColor: 'rgba(0, 0, 0, 0.2)',
    buttonHoverBackground: '#cad6bc', // light blue for light theme hover
    OppHoverBackground: '#0957d0', // darker shade for dark theme hover
    homeBackground: 'rgb(194 231 254 / 40%)',
    // backgroundIMG: `url(${flowerIMG})`, // Light mode background image URL
    pcolor1: '#386a20',
    pcolor2: '#9cd67d',
    pcolor3: '#205107',
    buttonHover: '#4b7c2a',
    buttonHoverSecondary: '#b0c6a2',
    overlayColor: 'rgb(56 106 32 /30%)',
    overlayColor: 'rgb(202 214 188 / 55%)',
    greyColor: '#0e7490',
    greenColor: '#386a20',
    blueColor: '#3c405b',
    redColor: '#9c4246',

}    



export const darkTheme = {
    mode: 'dark',
    primary:'#1976d2',
    primary: '#0957d0',
    primary: '#9cd67d',
    // primary: '#768b57',
    secondary:'rgb(217 231 202)',
    text: '#e3e3dc',
    textAlt: 'rgb(197 199 197)',
    textOpp: '#1a1c18',
    textGrey: '#afb8c4',
    backgroundColor: '#1b1c18',
    card: '#272e23',
    cardLighter: '#2d2f31',
    cardLight: '#232820',
    borderColor: '#28292a',
    borderColor2: 'rgb(197 199 197)',
    hoverShadowColor: '#afb8c4', 
    buttonHoverBackground: '#3d4a36', // darker shade for dark theme hover
    OppHoverBackground: 'rgb(194 231 254)',
    homeBackground: '#262626',
    // backgroundIMG: `url(${flowerDarkIMG})`, // Dark mode background image URL
    
    pcolor1: '#386a20',
    pcolor2: '#9cd67d',
    pcolor3: '#205107',

    buttonHover: '#89b96e',
    buttonHoverSecondary: 'rgb(86, 100, 76)',
    overlayColor: 'rgb(156 214 125 / 20%)',
    overlayColor: 'rgb(61 74 54 / 55%)',
    
    greenColor: '#9cd67d',
    redColor: '#ffb3b4',
    greyColor: '#b2ccd6',
    blueColor: '#0ca5e9',

}

export const redLightTheme = {
    mode: 'light',
    primary: '#9c4246',
    secondary: '#2d1517',
    text: '#1f1f1f',
    textAlt: 'rgb(69 71 70)',
    textOpp: '#fcfcfc',
    textGrey: '#afb8c4',
    backgroundColor: '#fcfcfd',
    card: '#f3eaeb',
    cardLight: '#f5f0f0',
    cardLighter: '#ffffff', 
    borderColor: '#e2e8f0', 
    borderColor2: '#e2e8f0',
    hoverShadowColor: 'rgba(0, 0, 0, 0.2)', 
    buttonHoverBackground: '#f1cecd',
    OppHoverBackground: '#0957d0', 
    homeBackground: 'rgb(194 231 254 / 40%)',
    overlayColor: 'rgb(156 66 70 / 20%)',
    overlayColor: 'rgb(241 206 205 / 55%)',
    buttonHover: '#a9555a',
    buttonHoverSecondary: '#e4bfbf',
    greyColor: '#0e7490',
    greenColor: '#386a20',
    blueColor: '#3c405b',
    redColor: '#9c4246',

};

export const redDarkTheme = {
    mode: 'dark',
    primary: '#ffb3b4',
    secondary: '#ffdad9',
    text: '#ede0df',
    textAlt: 'rgb(197 199 197)', 
    textOpp: '#1a1c18', 
    textGrey: '#afb8c4',
    backgroundColor: '#211a1a',
    card: 'rgb(54 42 41)',
    cardLight: 'rgb(47 37 37)',
    hoverShadowColor: '#afb8c4', // Adjust for red theme
    buttonHoverBackground: '#5c3f3f', 
    buttonHover: '#ff9fa0',
    buttonHoverSecondary: '#6e5555',
    OppHoverBackground: 'rgb(194 231 254)',
    homeBackground: '#262626', 
    overlayColor: 'rgb(255 179 180 / 25%)',
    overlayColor: 'rgb(92 63 63 / 55%)',
    redColor: '#ffb3b4',
    greenColor: '#9cd67d',
    greyColor: '#b2ccd6',
    blueColor: '#0ca5e9',

};

export const lightGreyTheme = {
    mode: 'light',
    primary:'#0e7490',
    secondary: 'rgb(1 99 155)',
    secondary: '#0f1b61',
    text: '#0f1b61',
    textAlt: 'rgb(69 71 70)',
    textGrey: '#afb8c4',
    backgroundColor: '#f8fafc',
    card: '#e2e8f0',
    cardLight: '#f1f5f9',
    cardLighter: '#f4f6fc',
    cardLighter: '#ffffff',
    borderColor: '#e2e8f0',
    borderColor2: '#e2e8f0',
    hoverShadowColor: 'rgba(0, 0, 0, 0.2)',
    buttonHoverBackground: '#d3dae1',
    OppHoverBackground: '#004a77', 
    homeBackground: 'rgb(194 231 254 / 40%)',
    overlayColor: 'rgb(13 116 144 / 30%)',
    textOpp: '#fff',
    greyColor: '#0e7490',
    greenColor: '#386a20',
    blueColor: '#3c405b',
    redColor: '#9c4246',
    buttonHover: '#0b6881',
    buttonHoverSecondary: '#bdc5cc',
    activeColor: '#a5b4fc',
}

export const darkGreyTheme = {
    mode: 'dark',
    primary: '#62efbd',
    secondary:'#b2ccd6',
    text: '#b2ccd6',
    textAlt: '##768b9d',
    textGrey: '#1b252d',
    backgroundColor: '#242933',
    backgroundColor: '#21252e',
    card: '#2a303c',
    card: '#2f3542',
    cardLighter: '#2d2f31',
    cardLight: '#242933',
    cardLight: '#2a303c',
    borderColor: '#28292a',
    borderColor2: 'rgb(197 199 197)',
    hoverShadowColor: '#afb8c4', 
    activeColor: '#3730a3',
    
    buttonHoverBackground: '#434c5f',
    OppHoverBackground: 'rgb(194 231 254)',
    homeBackground: '#262626',
    cardBright: '#353940',
    greyColor: '#b2ccd6',
    greenColor: '#9cd67d',
    redColor: '#ffb3b4',
    blueColor: '#0ca5e9',
    textOpp: 'rgb(31 31 31)',
    overlayColor: 'rgb(77 87 108 / 50%)',
    textDark: '#656a6f',
    buttonHover: '#58d6a9',
    buttonHoverSecondary: '#4c576c',
}

export const lightBlueTheme = {
    mode: 'light',
    primary:'#3c405b',
    secondary: 'rgb(1 99 155)',
    secondary: '#0f1b61',
    text: '#3c405b',
    textAlt: 'rgb(69 71 70)',
    textGrey: '#afb8c4',
    backgroundColor: '#fefbed',
    card: '#f5f1de',
    cardLight: '#f5f1de',
    cardLighter: '#f4f6fc',
    cardLighter: '#ffffff',
    borderColor: '#e2e8f0',
    borderColor2: '#e2e8f0',
    hoverShadowColor: 'rgba(0, 0, 0, 0.2)',
    buttonHoverBackground: '#aacebc',
    OppHoverBackground: '#004a77', 
    homeBackground: 'rgb(194 231 254 / 40%)',
    overlayColor: 'rgb(130 178 154 / 35%)',
    textOpp: '#fff',
    greyColor: '#0e766e',
    blueColor: '#3c405b',
    greenColor: '#386a20',
    redColor: '#9c4246',
    buttonHover: '#494e6f',
    buttonHoverSecondary: '#9ec0af',
    activeColor: 'rgb(224 122 93)',
}

export const darkBlueTheme = {
    mode: 'dark',
    primary: '#0ca5e9',
    secondary:'#b2ccd6',
    text: '#ffffff',
    textAlt: '#93a4b8',
    textGrey: '#1b252d',
    backgroundColor: '#0e172a',
    card: '#2a303c',
    card: '#1e293b',
    cardLight: '#191a2e',
    cardLight: 'rgb(27 38 56)',
    cardLighter: '#2d2f31',
    borderColor: '#28292a',
    borderColor2: 'rgb(197 199 197)',
    hoverShadowColor: '#afb8c4', 
    activeColor: '#3730a3',
    buttonHoverBackground: '#434c5f',
    OppHoverBackground: 'rgb(194 231 254)',
    homeBackground: '#262626',
    cardBright: '#353940',
    greyColor: '#b2ccd6',
    greenColor: '#9cd67d',
    blueColor: '#0ca5e9',
    redColor: '#ffb3b4',
    textOpp: 'rgb(31 31 31)',
    overlayColor: 'rgb(12 165 233 / 33%)',
    textDark: '#656a6f',
    buttonHover: '#0990cc',
    buttonHoverSecondary: '#4c576c',
}

// --mio-theme-color-surface-1: #f8fafd;
// --mio-theme-color-surface-2: #f3f6fc;
// --mio-theme-color-surface-3: #eff3fa;
// --mio-theme-color-surface-4: #edf2fa;
// --mio-theme-color-surface-5: #eaf0f9;